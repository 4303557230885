<template>
  <Section>
    <Billboard>
      <template slot="billboard-title">
        <h2 class="billboard__title">
          {{ $t(`home_banner.title.p_1`) }}<span v-if="$i18n.locale === 'ru'">{{
            this.city.declensions.prepositional
          || this.city.title || this.city.name }}</span>?
          {{ $t(`home_banner.title.p_2`) }}
        </h2>
      </template>
      <template slot="billboard-description">
        <span class="billboard__description">
          {{ $t(`home_banner.desc`) }} {{ this.city.declensions.nominative ||
        this.city.title || this.city.name }}
        </span>
      </template>
      <template slot="billboard-buttons">
        <div class="billboard__buttons">
          <Button :full="$mobile"
            color="main"
            v-on:click.native="$openPopupAddObject">{{ $t('service.add_obj') }}
          </Button>
          <a :href="$city.slug === 'pjatigorsk' ? 'https://pjatigorsk.stav-smart.ru/' : 'https://smart-fe26.ru'">
            <Button :full="$mobile"
              color="main">
              {{ $t(`home_banner.smart`) }}
              {{ this.city.declensions.nominative || this.city.title || this.city.name }}
            </Button>
          </a>
        </div>
      </template>
    </Billboard>
  </Section>
</template>

<script>
export default {
  name: 'ModifiedBillboard',
  computed: {
    city() {
      return this.$store.getters.GET_CITY;
    },
  },
};
</script>
